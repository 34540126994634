var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"models-table",attrs:{"column":"","fill-height":""}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-card-title',{staticClass:"row wrap px-0 pt-1"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_c('span',{staticClass:"display-1 font-weight-thin"},[_vm._v("Models")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"pr-2",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"paste":function($event){$event.preventDefault();return _vm.searchOnPaste.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.ability.can('create', 'Model'))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{ name: 'create-model' }}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,1957800219)},[_c('span',[_vm._v("New Model")])])]:_vm._e()],2)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.models,"footer-props":_vm.footerProps,"loading":_vm.$apollo.loading,"options":_vm.options,"server-items-length":_vm.modelsCount,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(role,i){return _c('div',{key:i,domProps:{"textContent":_vm._s(role.name)}})})}},{key:"item.assetType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._.startCase(item.assetType)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.ability.can('update', item))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","text":"","to":{ name: 'edit-model', params: { id: item.id } }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]:_vm._e()]}}])}),_c('pagination',{attrs:{"options":_vm.options,"totalPage":_vm.totalPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }