import Pagination from '@/components/Pagination/Pagination.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import QueryMixin from '@/mixins/query.mixin'

export default {
  props: ['updatedItem'],
  mixins: [GraphQlMixin, QueryMixin],
  components: {
    Pagination
  },
  data() {
    return {
      models: [],
      modelsCount: 0,
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Asset Type', value: 'assetType' },
        { text: 'Model Number', value: 'modelNumber' },
        { text: 'Model Year', value: 'modelYear' },
        { text: 'Cmanage Model', value: 'cmanageModel' },
        { text: 'Manufacturer/Carrier', value: 'manufacturer.name' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      search: '',
      debouncing: false,
      debounceTimeout: null
    }
  },
  computed: {
    totalPage() {
      return this.options.itemsPerPage === -1 || this.modelsCount === 0
        ? 1
        : Math.ceil(this.modelsCount / this.options.itemsPerPage)
    }
  },
  watch: {
    options: {
      handler() {
        this.$apollo.queries.models.refetch()
      },
      deep: true
    },
    search() {
      this.$apollo.queries.models.refetch()
    },
    // Everytime the route changes, reset search
    $route(to, from) {
      if (to.path !== from.path) {
        this.search = ''
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== from.path) {
      this.search = ''
    }
    next()
  },
  apollo: {
    models: {
      query() {
        const fields = this.getFieldsGql('read', 'Model', [
          'id',
          'name',
          'assetType',
          'modelNumber',
          'modelYear',
          'cmanageModel',
          { name: 'manufacturer', type: 'Manufacturer', fields: ['id', 'name'] }
        ])
        return this.$gql`
          query SearchModels(
            $q: String
            $take: Int
            $skip: Int
            $orderBy: [OrderBy]
          ) {
            models(search: $q, take: $take, skip: $skip, orderBy: $orderBy) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return {
          q: this.search,
          take: this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
          skip: this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage * (this.options.page - 1),
          orderBy: this.getOrderBy()
        }
      },
      skip() {
        return (
          !_.get(this, 'options.page') || this.debouncing || this.loadingQuery || !this.ability.can('read', 'Model')
        )
      },
      result({ data, error }) {
        if (data && data.models) {
          if (this.updatedItem !== undefined) {
            for (let i = 0; i < data.models.items.length; i++) {
              if (data.models.items[i].id === this.updatedItem.id) {
                data.models.items[i] = this.updatedItem
                break 
              }
            }
          }
          this.models = data.models.items
          this.modelsCount = data.models.totalCount
        } else if (error) {
          this.graphQLOnError(`Failed to get list of models. ${error.toString()}`)
        }
      }
    }
  },
  created() {
    if (!this.ability.can('read', 'Model')) {
      this.$router.push('/')
    }
    this.search = ''
  }
}
